<template>
  <div class="nav-wrap">
    <router-link to="/index">
      <div class="item">
        <div :class="`image i-1 ${index == 0?'active':''}`"></div>
        <div class="name">文化数据</div>
      </div>
    </router-link>
    <router-link to="/trend">
      <div class="item">
        <div :class="`image i-2 ${index == 1?'active':''}`"></div>
        <div class="name">流转趋势</div>
      </div>
    </router-link>
    <router-link to="/chain/index">
      <div class="item">
        <div :class="`image i-3 ${index == 2?'active':''}`"></div>
        <div class="name">链上公示</div>
      </div>
    </router-link>
    <router-link to="/networkNode">
      <div class="item">
        <div :class="`image i-4 ${index == 3?'active':''}`"></div>
        <div class="name">网络节点</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['index'],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.nav-wrap {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  bottom: 0;
  font-size: 0.2rem;
  background: #090000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.16rem 0.85rem;
  .item {
    .image {
      width: 0.47rem;
      height: 0.37rem;
      background-size: cover;
background-position:center center;
      margin: 0 auto;
    }
    .i-1 {
      background-image: url("../../assets/h5_tab_icon_culture_default.png");
      &.active {
        background-image: url("../../assets/h5_tab_icon_culture_selected.png");
      }
    }
    .i-2 {
      background-image: url("../../assets/h5_tab_icon_trend_default.png");
      &.active {
        background-image: url("../../assets/h5_tab_icon_trend_selected.png");
      }
    }
    .i-3 {
      background-image: url("../../assets/h5_tab_icon_publicity_default.png");
      &.active {
        background-image: url("../../assets/h5_tab_icon_publicity_selected.png");
      }
    }
    .i-4 {
      background-image: url("../../assets/h5_tab_icon_code_default.png");
      &.active {
        background-image: url("../../assets/h5_tab_icon_code_selected.png");
      }
    }
  }
  .name {
    font-size: 0.2rem;
    color: #c6cee2;
    padding-top: 0.12rem;
    &.active {
      color: #45c2ca;
    }
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
}
</style>