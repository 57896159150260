<template>
  <div id="app">
    <PCNavheader />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view :key="$route.path + $route.query.t"/>
    <div class="web-footer">
      <div>区块链底层由维公链提供</div>
      <div>区块链备案号：<a href="https://bcbeian.ifcert.cn">粤网信备4401121967872434001X号</a></div>
    </div>
  </div>
</template>
<script>
import PCNavheader from '@/components/PCNavheader' 
export default {
  components: {
    PCNavheader,
  },
}
</script>

<style lang="scss">
body {margin:0; padding:0;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  // min-height: 100vh;
  // background: #18184D;;
}
html {
  background: #090000;;
}
#nav {
  padding: 30px;

  // a {
  //   font-weight: bold;
  //   color: #2c3e50;

  //   &.router-link-exact-active {
  //     color: #42b983;
  //   }
  // }
}
.router-link-active {
  text-decoration: none;
  color: #FF4630;
}
a {
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #FF4630;
  }
}
.cell {
  a {
    color: #FF4630;
    &:hover {
      color:#18184D;
    }
  }
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.web-footer {
  text-align: center;
  font-size: 14px;
  padding: 40px;
  @media screen and (max-width: 1200px) {
    font-size: 0.2rem;
    margin: 0.4rem 0;
  }
}
</style>
