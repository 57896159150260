<template>
  <div class="NetworkNode">
    <H5Navheader :name="'网络节点'" />
    <div class="main-body">
      <div class="node-status-wrap-pc">
        <div class="info-wrap">
          <div class="data-card-list">
            <div class="item">
              <div class="type-image i-1"></div>
              <div class="content-wrap">
                <div class="title">网络节点</div>
                <div class="amount" v-loading="dataLoading">
                  <el-statistic group-separator="," :precision="0" :value="Number(baseInfo.count)"></el-statistic>
                  <!-- {{baseInfo.node}} -->
                </div>
                <div class="text">NODE</div>
              </div>
            </div>
            <div class="item">
              <div class="type-image i-2"></div>
              <div class="content-wrap">
                <div class="title">区块高度</div>
                <div class="amount" v-loading="dataLoading">
                  <el-statistic group-separator="," :precision="0" :value="Number(baseInfo.blockHash)"></el-statistic>
                  <!-- {{baseInfo.blockNumber}} -->
                </div>
                <div class="text">HEIGHT</div>
              </div>
            </div>
          </div>
          <div class="cell-list">
            <div class="title">节点状态</div>
            <div class="list" v-loading="dataLoading">
              <div class="header">
                <div class="text">状态</div>
                <div class="text">数量（个）</div>
              </div>
              <div class="item">
                <div class="label">正常</div>
                <div class="value">{{nodeStatus.OnLine}}</div>
              </div>
              <div class="item">
                <div class="label">待启动</div>
                <div class="value">{{nodeStatus.PreEnable}}</div>
              </div>
              <div class="item">
                <div class="label">掉线</div>
                <div class="value">{{nodeStatus.DISCONNECT}}</div>
              </div>
              <!-- <div class="item">
                <div class="label">已过期</div>
                <div class="value">{{nodeStatus.OffLine}}</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="bg-image flash"></div>
      </div>

      <div class="table-wrap-pc" style="padding-bottom: 60px">
        <div class="title">节点</div>
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column prop="createTime" label="时间" width="180">
            <!-- <template slot-scope="scope">
              <span>{{$moment(scope.row.lastpaidtime).format("YYYY MM DD HH:mm:ss")}}</span>
            </template> -->
          </el-table-column>
          <el-table-column prop="ip" label="地址" width="180">
          </el-table-column>
          <!-- <el-table-column prop="" label="版本"></el-table-column> -->
          <el-table-column prop="lastpaidblock" label="高度"></el-table-column>
          <el-table-column prop="country" label="国家"></el-table-column>
          <el-table-column prop="city" label="城市"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <!-- <span>{{scope.row.status === 'DISCONNECT'?'掉线':scope.row.status === 'preEnable'?'待启动':'正在运行'}}</span> -->
              <el-tag type="success" effect="dark" size="mini"  v-if="scope.row.status === 'OnLine'">正在运行</el-tag>
              <el-tag type="info" effect="dark" size="mini"  v-if="scope.row.status === 'PreEnable'">待启动</el-tag>
              <el-tag type="danger"  effect="dark" size="mini" v-if="scope.row.status === 'DISCONNECT'">掉线</el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="address" label="网络"></el-table-column> -->
        </el-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="params.pageNum"
          :limit.sync="params.pageSize"
          @pagination="getList"
        />
      </div>

      <div class="h5-wrap">
        <div class="data-card-list">
          <div class="item">
            <div class="type-image i-1"></div>
            <div class="content-wrap">
              <div class="title">网络节点</div>
              <div class="amount" v-loading="dataLoading">
                <el-statistic group-separator="," :precision="0" :value="Number(baseInfo.count)"></el-statistic>
                <!-- {{baseInfo.node}} -->
              </div>
              <div class="text">NODE</div>
            </div>
          </div>
          <div class="item">
            <div class="type-image i-2"></div>
            <div class="content-wrap">
              <div class="title">区块高度</div>
              <div class="amount" v-loading="dataLoading">
                <el-statistic group-separator="," :precision="0" :value="Number(baseInfo.blockHash)"></el-statistic>
                <!-- {{baseInfo.blockNumber}} -->
              </div>
              <div class="text">HEIGHT</div>
            </div>
          </div>
        </div>
        <div class="status-wrap">
          <div class="cell-list">
            <div class="title">节点状态</div>
            <div class="item">
              <div class="text status">
                <div class="value">正常</div>
                <div class="label">状态</div>
              </div>
              <div class="text">
                <div class="value">{{nodeStatus.OnLine}}</div>
                <div class="label">数量（个）</div>
              </div>
            </div>
            <div class="item">
              <div class="text status">
                <div class="value">待启动</div>
                <div class="label">状态</div>
              </div>
              <div class="text">
                <div class="value">{{nodeStatus.PreEnable}}</div>
                <div class="label">数量（个）</div>
              </div>
            </div>
            <div class="item">
              <div class="text status">
                <div class="value">掉线</div>
                <div class="label">状态</div>
              </div>
              <div class="text">
                <div class="value">{{nodeStatus.DISCONNECT}}</div>
                <div class="label">数量（个）</div>
              </div>
            </div>
            <!-- <div class="item">
              <div class="text status">
                <div class="value">已过期</div>
                <div class="label">状态</div>
              </div>
              <div class="text">
                <div class="value">{{nodeStatus.OffLine}}</div>
                <div class="label">数量（个）</div>
              </div>
            </div> -->
          </div>
          <div class="bg-image flash"></div>
        </div>
        <div class="list-wrap-h5">
          <div class="title">节点</div>
          <div class="card-item" v-for="item in tableData" :key="item.id">
            <div class="info address">
              <div class="value">{{item.ip}}</div>
              <div class="label">地址</div>
            </div>
            <div class="info time">
              <div class="value">{{item.createTime}}</div>
              <div class="label">时间</div>
            </div>
            <!-- <div class="info version">
              <div class="value">/Satoshi:0.19.0.1/90007</div>
              <div class="label">版本</div>
            </div> -->
            <!-- <div class="info network">
              <div class="value">Hang Alibaba AdvertislingCo,Ltd.A537663</div>
              <div class="label">网络</div>
            </div> -->
            <div class="info height">
              <div class="value">{{item.lastpaidblock}}</div>
              <div class="label">高度</div>
            </div>
            <div class="info country">
              <div class="value">{{item.country}}</div>
              <div class="label">国家</div>
            </div>
            <div class="info city">
              <div class="value">{{item.city}}</div>
              <div class="label">城市</div>
            </div>
          </div>
          <div v-if="loading" class="h5-loading-wrap"><van-loading type="spinner" size="24px" color='#fff'>加载中...</van-loading></div>
        </div>
      </div>
    </div>
    <H5Tab :index="3" />
  </div>
</template>
<script>
import H5Tab from "@/components/H5Tab";
import H5Navheader from "@/components/H5Navheader";
import { networkNode, getNoodeInfoStatus, getNoodeNum } from "@/api/index.js";

export default {
  name: "NetworkNode",
  components: {
    H5Tab,
    H5Navheader,
  },
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10,
        orderByColumn: 'create_time',
        isAsc: 'desc'
      },
      total: 0,
      tableData: [],
      baseInfo: {
        blockHash: 0,
        count: 0,
      },
      nodeStatus: {
        OnLine: '...',
        PreEnable: '...',
        DISCONNECT: '...',
        OffLine: '...',
      },
      loading: true,
      pages: 0,
      dataLoading: true,
    };
  },
  created() {
    window.addEventListener('scroll', this.scrollBottom);
    // this.getData()
    this.getList()
    // this.getStatusNum()
    this.promiseAll()
  },
  mounted() {
    
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollBottom) //页面离开后销毁监听事件
  },
  methods: {
    /** 获取数量统计 */
    getData() {
      
      return new Promise((resolve,reject) => {
        getNoodeNum().then(res => {
          if (res.code == 200) {
            resolve(res.data);
          }
       
        }).catch((err) => {
          this.$message.error(err);
          reject();
        })
      })
    },
    /** 获取状态数量统计 */
    getStatusNum() {
      return new Promise((resolve,reject)=>{
        getNoodeInfoStatus().then(res => {
          resolve(res.data)
        }).catch(err => reject(err))
      })
    },
    promiseAll() {
      this.dataLoading = true;
      if (this.isMobile()) {
        this.$toast.loading({
          mask: true,
          message: '加载中...',
          duration: 0,
        });
      }
      Promise.all([
        this.getData(),
        this.getStatusNum(),
      ]).then((res) => {
        // console.log(res);
        this.baseInfo = res[0];
        this.nodeStatus.OnLine = res[1].filter(item => item.status === 'OnLine')[0]?.num || 0
        this.nodeStatus.PreEnable = res[1].filter(item => item.status === 'PreEnable')[0]?.num || 0
        this.nodeStatus.DISCONNECT = res[1].filter(item => item.status === 'DISCONNECT')[0]?.num || 0
        this.nodeStatus.OffLine = res[1].filter(item => item.status === 'OffLine')[0]?.num || 0
        this.dataLoading = false
        this.$toast.clear();
      });
    },
    /** 分页 */
    getList() {
      this.loading = true
      networkNode(this.params).then(res => {
        // console.log(res)
        this.tableData = res.rows
        this.total = res.total
        this.pages = res.pages
        this.loading = false
      })
    },
    /** h5 触底加载 */
    scrollBottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight + 150 >= scrollHeight && !this.loading  && this.params.pageNum < this.pages) {
        if (this.isMobile()) {
          // alert('移动端到底了')
          this.loading = true
          this.params.pageNum ++
          networkNode(this.params).then((res) => {
            this.tableData = this.tableData.concat(res.rows)
            // console.log(this.tableData)
            // this.total = res.total
            this.loading = false
          })
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.NetworkNode {
  .node-status-wrap-pc {
    display: flex;
    justify-content: space-between;
    .bg-image {
      width: 736px;
      height: 740px;
      background-size: cover;
      background-position: center center;
      background-image: url("../assets/bg_earth.png");
      flex-shrink: 0;
    }
    .info-wrap {
      .data-card-list {
        .item {
          position: relative;
          display: flex;
          align-items: center;
          background: url("../assets/bg_total.png");
          width: 370px;
          height: 152px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          margin-bottom: 30px;
          .type-image {
            width: 101px;
            height: 73px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            position: absolute;
            left: 16px;
            top: 43px;
          }
          .i-1 {
            background-image: url("../assets/icon_node.png");
          }
          .i-2 {
            background-image: url("../assets/icon_height.png");
          }
          .content-wrap {
            padding-left: 160px;
            .title {
              font-size: 14px;
            }
            .amount {
              font-size: 24px;
              color: #FF4630;
              padding: 14px 0;
            }
            .text {
              font-size: 14px;
            }
          }
        }
      }
      .cell-list {
        margin-top: 58px;
        > .title {
          display: inline-block;
          font-size: 18px;
          padding: 15px 25px;
          border-bottom: 1px solid #FF4630;
        }
        .list {
          .header {
            padding: 16px 29px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            background-color: #110605;
          }
          .item {
            background-color: #0F0504;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 28px;
            .label {
              width: 235px;
            }
            .value {
              flex-grow: 1;
              text-align: left;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .h5-wrap {
    .data-card-list {
      .item {
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        background-image: url("../assets/h5_bg_total.png");
        width: 7.5rem;
        height: 2.3rem;
        background-size: cover;
        background-position: center center;
        margin-bottom: 0.3rem;
        &:last-child {
          margin-bottom: 0;
        }
        .type-image {
          width: 1.53rem;
          height: 1.11rem;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          left: 0.19rem;
          top: 0.59rem;
        }
        .i-1 {
          background-image: url("../assets/h5_con_node.png");
        }
        .i-2 {
          background-image: url("../assets/h5_icon_height.png");
        }
        .content-wrap {
          padding-left: 2.42rem;
          .title {
            font-size: 0.21rem;
          }
          .amount {
            font-size: 0.36rem;
            color: #FF4630;
            padding: 0.22rem 0;
          }
          .text {
            font-size: 0.21rem;
          }
        }
      }
    }
    .status-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .bg-image {
        width: 3.06rem;
        height: 5.68rem;
        background-image: url("../assets/h5_bg_earth.png");
        background-size: cover;
        background-position: center center;
      }
      .cell-list {
        width: 3.9rem;
        box-sizing: border-box;
        .title {
          font-size: 0.28rem;
          display: inline-block;
          padding: 0.3rem 0.93rem;
          border-bottom: 1px solid #FF4630;
          margin-bottom: 0.53rem;
        }
        .item {
          margin-left: 0.24rem;
          padding: 0.28rem;
          display: flex;
          align-items: center;
          .text {
            &.status {
              width: 1.9rem;
            }
            .value {
              font-size: 0.24rem;
              color: #fff;
              padding-bottom: 0.06rem;
            }
            .label {
              font-size: 0.2rem;
              color: #ababff;
            }
          }
          &:nth-child(even) {
            background-color: #110605;
          }
          &:nth-child(odd) {
            background-color: #0B0202;
          }
        }
      }
    }
    .list-wrap-h5 {
      padding: 0.3rem 0.24rem;
      > .title {
        display: inline-block;
        font-size: 0.28rem;
        padding: 0.29rem 0.93rem;
        border-bottom: 1px solid #FF4630;
      }
      .card-item {
        padding: 0.3rem;
        margin: 0.3rem 0;
        &:nth-child(even) {
          background: #110605;
        }
        &:nth-child(odd) {
          background: #0B0202;
        }
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        word-break: break-all;
        .info {
          padding-bottom: 0.3rem;
          vertical-align: middle;
          .value {
            font-size: 0.24rem;
            padding-bottom: 0.16rem;
            line-height: 0.24rem;
          }
          .label {
            font-size: 0.2rem;
            color: #ababff;
          }
        }
        .address,
        .time,
        .version,
        .network {
          width: 50%;
        }
        .height,
        .country,
        .city {
          min-width: 25%;
        }
      }
      
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}

</style>
<style lang="scss">
.flash {
  animation: myFlash 2s infinite;
  -webkit-animation: myFlash 2s infinite; /* Safari and Chrome */
  animation-direction: alternate;
  -webkit-animation-direction: alternate; /* Safari 和 Chrome */
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out; /* Safari and Chrome */
}
@keyframes myFlash {
  0% {
    opacity: 1; //透明程度
  }
  100% {
    opacity: 0.4;
  }
}
</style>
